import { AccountMembership } from '@mkp/account-membership/data-access';
import { AccountMembershipRole } from '@mkp/account-membership/models';
import { createActionGroup, props } from '@ngrx/store';

export const editPermissionsActions = createActionGroup({
  source: 'Edit permissions',
  events: {
    'Roles Saved': props<{
      roles: { role: AccountMembershipRole }[];
      _version: string;
      accountMembershipId: string;
    }>(),
    'Patch Roles Success': props<{
      accountMembership: AccountMembership;
    }>(),
    'Patch Roles Failure': props<{ status: number }>(),
  },
});
