import { LegalEntity } from '@mkp/legal-entity/data-access';
import { AccountDto } from './account.dto';
import { Account, ATS_NAMES, SettingsAtsName } from './account.model';

// just in case we find an unknown value, should not happen
const DEFAULT_ATS = 'INTERNAL';

export const mapAccountDtoToAccount = (dto: AccountDto, legalEntity: LegalEntity): Account => ({
  id: dto.id,
  name: dto.name,
  state: dto.state,
  legalEntity,
  createdAt: new Date(dto.createdAt),
  settings: {
    ats: {
      name: getAtsName(dto.settings.ats.name),
    },
  },
  computed: {
    canCreateUser: !!dto._links.createAccountMembership,
  },
  _version: dto._version,
  _links: dto._links,
});

const getAtsName = (atsName: string): SettingsAtsName => {
  if (!isAtsName(atsName)) {
    console.error(`Unknown ATS: ${atsName}`);
    return DEFAULT_ATS;
  }

  return atsName;
};

const isAtsName = (atsName: string): atsName is SettingsAtsName =>
  ATS_NAMES.includes(atsName as SettingsAtsName);
