import {
  ProductOfferingBenefit,
  ProductOfferingPlatform,
  ProductPlatformGroup,
  ProductPlatformOther,
} from '../models/product-offering-feature.model';
import { ProductCode } from '../models/product.model';

export type Features = ProductOfferingBenefit | ProductOfferingPlatform;

export type ProductFeatures = { productCode: ProductCode; features: Features[] };

export const PRODUCT_FEATURES: ProductFeatures[] = [
  {
    productCode: ProductCode.JS24FREE,
    features: [
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.Calendar10Days,
    ],
  }, // free, visible on UI
  {
    productCode: ProductCode.JS24SOLO,
    features: [
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.InboxIn,
      ProductOfferingBenefit.GoogleJobPosting,
      ProductOfferingBenefit.Calendar,
    ],
  },
  {
    productCode: ProductCode.JS24BASIC,
    features: [
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.InboxIn,
      ProductOfferingBenefit.GoogleJobPosting,
      ProductOfferingBenefit.Calendar,
    ],
  },
  {
    productCode: ProductCode.JOBSSTARTER,
    features: [
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.InboxIn,
      ProductOfferingBenefit.GoogleJobPosting,
      ProductOfferingBenefit.Calendar,
    ],
  }, // visible on UI
  {
    productCode: ProductCode.JS24PLUS,
    features: [
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.InboxIn,
      ProductOfferingBenefit.GoogleJobPosting,
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductPlatformOther.JobWinner,
      ProductOfferingBenefit.Calendar,
    ],
  }, // visible on UI
  {
    productCode: ProductCode.JS24ADVANCED,
    features: [
      ProductOfferingBenefit.OnlineATS,
      ProductOfferingBenefit.InboxIn,
      ProductOfferingBenefit.GoogleJobPosting,
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductPlatformOther.JobWinner,
      ProductOfferingBenefit.Calendar,
    ],
  }, // visible on UI
  {
    productCode: ProductCode.JOBSOFFER22FREE,
    features: [
      ProductPlatformGroup.Jobs,
      ProductOfferingBenefit.Calendar10Days,
      ProductOfferingBenefit.Performance,
    ],
  }, // free, visible on UI
  {
    productCode: ProductCode.JOBSOFFER22XS,
    features: [
      ProductPlatformGroup.Jobs,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.Calendar7Jobs,
      ProductOfferingBenefit.JobAlert1x,
    ],
  }, // paid, visible on UI
  {
    productCode: ProductCode.JOBSOFFER22S,
    features: [
      ProductPlatformGroup.Jobs,
      ProductOfferingBenefit.OnlineCampaignsCheckmark,
      ProductOfferingBenefit.Calendar,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert1x,
    ],
  }, // paid, visible on UI
  {
    productCode: ProductCode.JOBSOFFER22M,
    features: [
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineCampaignsCheckmark,
      ProductOfferingBenefit.Calendar,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert3x,
      ProductOfferingBenefit.JobBoost2x,
      ProductOfferingBenefit.PartnerNetworkCheckmark,
    ],
  }, // paid, visible on UI
  {
    productCode: ProductCode.JOBSOFFER22L,
    features: [
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineCampaignsBoost,
      ProductOfferingBenefit.Calendar45,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert5x,
      ProductOfferingBenefit.JobBoost4x,
      ProductOfferingBenefit.PartnerNetworkBoost,
    ],
  }, // paid, visible on UI
  {
    productCode: ProductCode.JOBSOFFER22XL,
    features: [
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.OnlineCampaignsPlus,
      ProductOfferingBenefit.Calendar60,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert11x,
      ProductOfferingBenefit.JobBoost10x,
      ProductOfferingBenefit.PartnerNetworkPlus,
    ],
  }, // paid, visible on UI

  {
    productCode: ProductCode.JOBUP24FREE,
    features: [
      ProductPlatformGroup.JobUp,
      ProductOfferingBenefit.Calendar10Days,
      ProductOfferingBenefit.Performance,
    ],
  },
  {
    productCode: ProductCode.JOBUP24LITE,
    features: [
      ProductPlatformGroup.JobUp,
      ProductOfferingBenefit.Calendar7Jobup,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert1x,
      ProductOfferingBenefit.OnlineCampaigns,
      ProductOfferingBenefit.PartnerNetwork,
    ],
  },
  {
    productCode: ProductCode.JOBUP24BASIC,
    features: [
      ProductPlatformGroup.JobUp,
      ProductOfferingBenefit.Calendar30,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert1x,
      ProductOfferingBenefit.OnlineCampaigns,
      ProductOfferingBenefit.PartnerNetwork,
    ],
  },
  {
    productCode: ProductCode.JOBUP24ADVANCED,
    features: [
      ProductPlatformGroup.JobUp,
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.Calendar30,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert3x,
      ProductOfferingBenefit.JobBoost2x,
      ProductOfferingBenefit.OnlineCampaigns,
      ProductOfferingBenefit.PartnerNetwork,
    ],
  },
  {
    productCode: ProductCode.JOBUP24ULTIMATE,
    features: [
      ProductPlatformGroup.JobUp,
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.Calendar45,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert5x,
      ProductOfferingBenefit.JobBoost4x,
      ProductOfferingBenefit.OnlineCampaignsBoost,
      ProductOfferingBenefit.PartnerNetworkBoost,
    ],
  },
  {
    productCode: ProductCode.JOBUP24ULTIMATEPLUS,
    features: [
      ProductPlatformGroup.JobUp,
      ProductPlatformGroup.Jobs,
      ProductPlatformGroup.JobScout24,
      ProductOfferingBenefit.Calendar60,
      ProductOfferingBenefit.Performance,
      ProductOfferingBenefit.JobAlert9x, // new - added to ProductOfferingBenefit
      ProductOfferingBenefit.JobBoost8x, // new - added to ProductOfferingBenefit
      ProductOfferingBenefit.OnlineCampaignsPlus,
      ProductOfferingBenefit.PartnerNetworkPlus,
    ],
  },
];
