import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { AccountResource } from '@mkp/account/data-access';
import { selectActiveAccounts } from '@mkp/account/state';
import { selectWorkspaceFromRouteGuardActions } from '@mkp/auth/actions';
import { selectWorkspaceSelecting } from '@mkp/auth/state';
import { SentryService } from '@mkp/core/util-sentry';
import { LoadingState } from '@mkp/shared/util-state';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';

const STATUS_ROUTE_MAP = {
  404: AppConfig.routes.error404,
  403: AppConfig.routes.error403,
  500: AppConfig.routes.error500,
};

const ACCOUNT_FOUND_ON_2ND_CALL_ERROR = new Error(
  'selectWorkspaceFromRouteGuard - found account on the 2nd call'
);

export const selectWorkspaceFromRouteGuard: (
  route: ActivatedRouteSnapshot
) => Observable<boolean | UrlTree> = (route) => {
  const store = inject(Store);
  const router = inject(Router);
  const sentryService = inject(SentryService);
  const accountResource = inject(AccountResource);

  const workspaceId = route.paramMap.get('workspaceId');
  const isWorkspaceLoaded$ = store
    .select(selectWorkspaceSelecting)
    .pipe(filter((state) => state === LoadingState.LOADED));

  // if account is not found in store: call API to decide where to redirect
  const callApiToDecideWhereToRedirect = (workspaceId: string) =>
    accountResource.getById(workspaceId, { maxRetry: 1 }).pipe(
      map(() => {
        // success should not happen here, but let's log the error just in case
        sentryService.captureException(ACCOUNT_FOUND_ON_2ND_CALL_ERROR);
        return getUrlTreeFromStatus(500, router);
      }),
      catchError((error: unknown) => of(getUrlTreeFromStatus(getStatus(error), router)))
    );

  return isWorkspaceLoaded$.pipe(
    take(1),
    // check if the account exists in the store
    concatLatestFrom(() => [store.select(selectActiveAccounts)]),
    map(([, accounts]) => accounts.find(({ id }) => id === workspaceId)?.id),
    tap((accountId) =>
      store.dispatch(selectWorkspaceFromRouteGuardActions.selectAccountIdFromRoute({ accountId }))
    ),
    switchMap((accountId) =>
      accountId
        ? of(true)
        : workspaceId
          ? // no account but workspace found in route: call API to decide where to redirect
            callApiToDecideWhereToRedirect(workspaceId)
          : // no workspace in route: redirect directly to /not-found
            of(getUrlTreeFromStatus(404, router))
    )
  );
};

const getStatus = (error: unknown): number | undefined => (error as HttpErrorResponse)?.status;
const getUrlTreeFromStatus = (status: number | undefined, router: Router): UrlTree => {
  return router.parseUrl(
    status && hasRoute(status) ? STATUS_ROUTE_MAP[status] : STATUS_ROUTE_MAP[500]
  );
};

const hasRoute = (status: number): status is keyof typeof STATUS_ROUTE_MAP =>
  status in STATUS_ROUTE_MAP;
